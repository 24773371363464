<template>
	<generic-data-editor
			title="Payment Transaction Types"
			collection-name="transaction-types"
	/>
</template>

<script>

import GenericDataEditor from '@/components/generic-data-editor';

export default {

    name      : 'TransactionTypes',
    components: { GenericDataEditor }

};

</script>